import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { SwiperComponent } from 'vitl-component-library';
// components
import SectionStandard from '../components/section-standard';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Reviews from '@components/reviews/reviews';
import HowItWorks from '../components/how-it-works';
import ExpertApproval from '../components/expert-approval';
import Image from '@components/image/image';
import { usePersonalizedPlus2WeekText } from '@hooks/directusHooks/usePersonalizedPlus2WeekText';
import StillHaveQuestion from '@components/still-have-question/still-have-question';
// styles
import styles from './personalised-plus-2week-trial.module.scss';
import couponCodeService, { offerReferrer } from '@src/utils/couponCodeService';

interface IProps {
  referrer: offerReferrer;
}

const PersonalisedPlus2WeekTrial: React.FC<IProps> = ({ referrer = 'none' }) => {
  const { landing, home, directusSetting, site } = usePersonalizedPlus2WeekText();
  const [activeVitamin, setActiveVitiamin] = useState(1);
  const { setOffer } = couponCodeService();

  useEffect(() => {
    setOffer('4pp', 'freeTrial', referrer);
  }, []);

  return (
    <Layout showFooter={false} showHeader={false}>
      <SEO title={landing.page_title} description={landing.page_description} noindex />

      <section className={styles.heroSection}>
        <Link to="/">
          <Image
            className={styles.logo}
            width={60}
            filename={directusSetting.logo.filename_disk}
            alt={site.siteMetadata.title}
          />
        </Link>{' '}
        <Image
          hideOnDesktop
          className={styles.heroImageMobile}
          width={464}
          filename={landing.hero_mobile_background_image.filename_disk}
        />
        <Image
          className={styles.heroImage}
          width={896}
          filename={landing.hero_background_image.filename_disk}
          hideOnTablet
        />
        <div className={styles.heroContainer}>
          <div className={styles.heroRow}>
            <div className={styles.heroCol}>
              <div className={styles.heroColContent}>
                <h1 className={styles.heroTitle}>Supplements personalised to your needs</h1>
                <ul className={styles.heroList}>
                  <li>
                    <Image alt="check mark" width={18} src={'/images/icon-check-green.svg'} /> Free
                    2-week trial worth £19.98{' '}
                  </li>
                  <li>
                    <Image alt="check mark" width={18} src={'/images/icon-check-green.svg'} /> Clean
                    ingredients{' '}
                  </li>
                  <li>
                    <Image alt="check mark" width={18} src={'/images/icon-check-green.svg'} /> Fully
                    personalised{' '}
                  </li>
                </ul>
                <Link to="/consultation" className={styles.heroButton}>
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.logos}>
        {landing.partner_logos.map((item: any, index: number) => (
          <Image
            key={index}
            src={`${process.env.GATSBY_MEDIA_URL}${item.image_hash}`}
            alt="Partner's Logo"
          />
        ))}
      </section>

      <section className={styles.sectionPersonalised}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.arrowContainer}>
              <Image alt="Down arrow" width={18} src={'/images/icon-long-arrow-down.svg'} />
            </div>
          </div>
          <div className={styles.contentPersonalTitle}>
            <h2>{landing.personal_title}</h2>
            <hr />
          </div>

          <SwiperComponent paginationType="bullets" spaceBetween={16} slidesPerView={1}>
            {landing.personal_items.map((item: any, index: number) => (
              <Image key={index} filename={item.image_hash_desktop} width={740} />
            ))}
          </SwiperComponent>

          <div className={styles.row}>
            <div className={styles.contentDescription}>
              <p>{landing.personal_description}</p>
            </div>
          </div>
        </div>
      </section>

      <SectionStandard
        direction="Left"
        background="GrayLightest"
        title="Clean ingredients to ensure maximum absorption"
        description="Traditional high street multivitamins are made using fillers and bulking agents to reduce manufacturing costs. At Vitl, we cut the chemicals so you can be sure you’re absorbing maximum goodness."
        image={{
          title: 'comparison',
          filename_disk: 'b11a0231-5449-4419-983a-eac02255bcf3.jpeg',
        }}
      />

      <Reviews
        showReview={true}
        title={landing.reviews_title}
        items={landing.reviews_items}
        hideTrustPilot
      />

      <HowItWorks title="How it works" items={landing.how_it_works_items} />

      <section className={styles.sectionPrimaryLighter}>
        <div className={styles.containerVitaminsDesktop}>
          <div className={styles.rowVitamins}>
            <div className={styles.leftContainer}>
              <div className={styles.imagesContainer}>
                {landing.vitamins_slides.map((slide: any, index: number) => (
                  <Image
                    className={activeVitamin === index ? styles.active : ''}
                    filename={slide.image_filename_disk}
                    alt={`Support ${slide.name}`}
                    key={index}
                    width={475}
                  />
                ))}
              </div>
            </div>
            <div className={styles.rightContainer}>
              <div className={styles.vitamins_list}>
                <h2>{landing.vitamins_title}</h2>
                <ul>
                  {landing.vitamins_slides.map((slide: any, index: number) => (
                    <li
                      className={activeVitamin === index ? styles.active : ''}
                      onMouseEnter={() => setActiveVitiamin(index)}
                      key={index}
                    >
                      <Image width={40} src={`landing-pages/${slide.name.toLowerCase()}.png`} />
                      <h3 className={styles.vitaminListText}>{slide.name}</h3>
                    </li>
                  ))}
                </ul>
                <Link to="/consultation" className={styles.vitaminButton}>
                  {landing.vitamins_button_label}
                </Link>
                <p
                  className={styles.vitaminFooter}
                  dangerouslySetInnerHTML={{
                    __html: landing.vitamins_footnote,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.containerVitaminsMobile}>
          <SwiperComponent paginationType="bullets" slidesPerView={1}>
            {landing.vitamins_slides.map((slide: any, index: number) => (
              <div>
                <div>
                  <h3 className={styles.h3}>
                    Looking for vitamins to support your{' '}
                    <span className={styles.underline}>{slide.name.toLowerCase()}</span>?
                  </h3>
                </div>
                <div>
                  <Image width={50} src={`landing-pages/${slide.name}.png`} />
                </div>
                <div className={styles.mobileVitaminImage}>
                  <img
                    src={`${process.env.GATSBY_MEDIA_URL}${slide.image_filename_disk}?w=345`}
                    alt={`Support ${slide.name}`}
                  />
                </div>
              </div>
            ))}
          </SwiperComponent>

          <Link to="/consultation" className={styles.vitaminButton}>
            {landing.vitamins_button_label}
          </Link>
          <p
            className={styles.vitaminFooter}
            dangerouslySetInnerHTML={{
              __html: landing.vitamins_footnote,
            }}
          />
        </div>
      </section>

      <ExpertApproval
        title="Expert approved"
        description="We combine the latest in nutritional science alongside your actual dietary and lifestyle data to create the perfect combination of nutrients for your body."
        experts={home.experts}
      />

      <StillHaveQuestion accordion={landing.faqs_items} description={landing.faqs_description} />
    </Layout>
  );
};

export default PersonalisedPlus2WeekTrial;
